import { Text, View, FlatList } from 'react-native';
import React, { useState } from 'react';
import Checkbox from 'expo-checkbox';


export default function TaskList() {
    const getIncomplete = async () => {
        try {
            const response = await fetch(
                'https://todo.thomasjeredbaker.com/api/tasks/getIncomplete.php'
            );
            const json = await response.json();
            setIncomplete(json);
        } catch (error) {
            console.error(error);
        }
    };

    const getComplete = async () => {
        try {
            const response = await fetch(
                'https://todo.thomasjeredbaker.com/api/tasks/getComplete.php'
            );
            const json = await response.json();
            setComplete(json);
        } catch (error) {
            console.error(error);
        }
    };

    const [incomplete, setIncomplete] = useState(getIncomplete);
    const [complete, setComplete] = useState(getComplete);

    const markDone = async (id) => {
        try {
            const response = await fetch(
                'https://todo.thomasjeredbaker.com/api/tasks/complete.php?id=' + id
            );
            setIncomplete(getIncomplete);
            setComplete(getComplete)
        } catch (error) {
            console.error(error);
        }
    };

    function Done() {
        return (
            <FlatList
                data={complete}
                renderItem={({ item }) => <Text style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{item.name}</Text>}
            />
        );
    }

    function Todo() {
        return (
            <FlatList
                data={incomplete}
                renderItem={({ item }) =>
                    <View style={{ flexDirection: 'row' }}>
                        <Checkbox
                            onValueChange={() => markDone(item.id)}
                        />
                        <Text onPress={() => markDone(item.id)}>{item.name}</Text>
                    </View>}
            />
        );
    }

    return (
        <View style={{ flex: 1, alignItems: 'center' }}>
            <View style={{ alignItems: 'center', paddingBottom: '20px' }}>
                <Text style={{ paddingBottom: '3px' }}>To-Do</Text>
                {Todo()}
            </View>
            <View style={{ alignItems: 'center' }}>
                <Text style={{ paddingBottom: '3px' }}>Complete</Text>
                {Done()}
            </View>
        </View>
    );
}